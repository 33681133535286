export const DEFAULT_BASE_URL = 'https://api-beta-restockify.shopgram.app';
export const CANNY_BOARD_TOKEN = 'f59c68b9-8292-3688-794a-d79a4d4afa72';
export const CANNY_APP_ID = '60740e55e7ff7c60ea60a308';

export const PATHS = {
  HOME: '/',
  HELP: '/help',
  LOGIN: '/support/login/',
  PRICING: '/plans',
  AUCTION: '/auction',
  CAMPAIGNS: '/campaigns',
  CREATE_CAMPAIGN: '/campaigns/create-campaign',
  EDIT_CAMPAIGN: '/campaigns/edit-campaign',
  CREATE_CAMPAIGNold: '/campaignsOld/create-campaign',
  EDIT_CAMPAIGNold: '/campaignsOld/edit-campaign',
  SETTING: '/setting',
  FEATURE_REQUEST: '/feature-request',
  WIDGETS: '/widgets',
  COUNTDOWN_SETTINGS: '/countdown-settings',
  HELP_SUB_PAGES: {
    CREATE_CAMPAIGN: 'create-campaign',
    IMPORTANT_TIPS: 'important-tips',
    FAQ: 'faq',
    CONTACT_US: 'contact-us',
    feature_request: 'feature-request',
  },
  SETTING_SUB_PAGES: {
    custom_notifications: 'customer-notification',
    MANAGE_DISCOUNTS: 'manage-discounts',
    Label_DISCOUNTS: 'lable-discount',
  },
  SELECT_CAMPAIGN: '/campaigns/create-selected-campaign',
};

export const PRICING_PERIODS = {
  MONTH: 0,
  YEAR: 1,
};
export const TABLE_ROWS_PER_PAGE = 1000;

export const primaryDiscountRule = {
  discountType: 0,
  discountValue: '',
  minValue: '',
  maxValue: '',
  minType: 1,
};
