import { Provider as AppBridgeProvider, NavigationMenu } from '@shopify/app-bridge-react';
import { isMobile, isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { AppProvider as PolarisProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import { isMobile,isShopifyEmbedded } from '@shopify/app-bridge-core';
import RouterLink from '@/shared/links/RouterLink';
import WholePageLoading from '@/shared/loaders/WholePageLoading';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import '@shopify/polaris/build/esm/styles.css';
import tbImg from 'assets/images/tb.jpg';
import 'assets/sass/app.scss';
import { PATHS } from 'config/constants';
import { useCanAddCampaign } from 'hooks/useCanAddCampaign';
import { usePermissions } from 'hooks/usePermissions';
import { useShopInfo } from 'hooks/useShopInfo';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { shopIsInstalled } from 'rest/dist/gen/api/shops/shops';
import { setApiAppBridge } from 'rest/dist/utils/fetchInstance';
import { getPlansAction } from 'states/actions/plans';
import Router from './Router';
import { api } from './api';
import { getShopInfoAction } from './states/actions/shop';

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-XMfFOBpXD7Nvb6vF',
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shopInfo = useShopInfo();
  const permissions = usePermissions();

  useEffect(() => {
    ReactGA.initialize('G-H8SVZLR973', {
      debug: false,
      gaOptions: {
        storage: 'none',
        storeGac: false,
      },
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    growthbook.loadFeatures();
  }, []);
  const [isLoggedInAsUser, setIsLoggedInAsUser] = useState(false);
  const loadingShopInfo = useSelector((state) => state?.shop?.loadingShopInfo);
  const loadingPermissions = useSelector((state) => state?.shop?.loadingPermissions);
  const canAddCampaign = useCanAddCampaign();
  useEffect(() => {
    // window.top !== window.self
    if (isMobile() || isShopifyEmbedded()) {
      const location = window.location;

      const queryParams = new URLSearchParams(location.search);
      // const queryParams = qs.parse(location.search.replace('?', ''));
      const host = queryParams.get('host');
      const shopDomain = queryParams.get('shop');
      // const locale = queryParams.get('locale');

      setApiAppBridge({ host, shopDomain });
      getShopInfoAction(dispatch)(host);
    } else {
      try {
        // let isInstalled = false;
        const location = window.location;
        const queryParams = new URLSearchParams(window.location.search);
        // const host = queryParams.get('host');
        const shopDomain = queryParams.get('shop');
        if (shopDomain) {
          shopIsInstalled({ shopifyDomain: shopDomain }).then((res) => {
            if (!res.data) {
              location.href =
                process.env.REACT_APP_API_BASE_URL + '/api/online/auth?shop=' + shopDomain;
            } else {
              var shopnewdomain = shopDomain.replace('.myshopify.com', '');
              var generalurl =
                // TODO: add to ENV after "/apps/"
                'https://admin.shopify.com/store/' + shopnewdomain + '/apps/discountprime';
              // location.href = shopDomain + '/admin/apps/discount-sales';
              location.href = generalurl;
            }
          });
        } else {
          setIsLoggedInAsUser(true);
          api.setAppBridge('', '', true);
          try {
            const location = window.location;

            const queryParams = new URLSearchParams(window.location.search);
          } catch (error) {}
          const token = sessionStorage.getItem('loggedInAsUserToken');
          const isLoginPage = window.location.pathname.includes('/support/login');
          if (!isLoginPage) {
            if (token) {
              getShopInfoAction(dispatch)();
            } else {
              window.open(window.location.origin + PATHS.LOGIN, '_self');
            }
          } else {
            getShopInfoAction(dispatch)('', true);
          }
        }
      } catch (err) {
        console.error(err);
      }

      // const token = sessionStorage.getItem('loggedInAsUserToken');
      // //const isLoginPage = window.location.pathname.includes('/support/login');
      // const isLoginPage = true;www

      // if (!isLoginPage) {
      //   if (token) {
      //     getShopInfoAction(dispatch)();
      //   } else {
      //     window.open(window.location.origin + PATHS.LOGIN, '_self');
      //   }
      // } else {
      //   getShopInfoAction(dispatch)('', true);
      // }
    }
  }, [dispatch]);

  // const setShopInfoForHotjar = async () => {
  //   const shopRelatedData = {
  //     contact_email: shopInfo?.data?.customer_email,
  //     shop_domain: shopInfo?.data?.domain,
  //     name: shopInfo?.data?.name || shopInfo?.data?.shop_owner,
  //     shop_name: shopInfo?.data?.name,
  //     user_id: shopInfo?.data?.id,
  //     email: shopInfo?.data?.email,
  //     Contact_Email: shopInfo?.data?.contact_email,
  //     Company_website: shopInfo?.data?.domain,
  //     Company_ID: shopInfo?.data?.id,
  //     Company_name: shopInfo?.data?.name,
  //     Company_Shopify_Domain: shopInfo?.data?.shopify_domain,
  //     Company_Shopify_Plan: shopInfo?.data?.shopify_plan,
  //   };
  //   // window._loq = window._loq || [];
  //   // window._loq.push(['custom', shopRelatedData]);
  //   window.hj('identify', shopRelatedData.user_id, shopRelatedData);
  // };

  useEffect(() => {
    getPlansAction(dispatch)();
  }, [shopInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const shopData = {
      shop_domain: shopInfo?.data?.shopify_domain,
      user_id: shopInfo?.data?.id,
      id: shopInfo?.data?.intercom_contact_id,
      email: shopInfo?.data?.customer_email,
      Phone: shopInfo?.data?.phone,
      owner: shopInfo?.data?.owner,
      name: shopInfo?.data?.shop_owner,
      'Contact Email': shopInfo?.data?.contact_email,
      'Company website': shopInfo?.data?.domain,
      'Company ID': shopInfo?.data?.id,
      // 'RightCopy Installed at': convertDateToTimestamp(
      //   shopInfo?.data?.last_install_time ?? new Date()
      // ),
      // 'RightCopy Uninstalled at': convertDateToTimestamp(
      //   shopInfo?.data?.last_uninstall_time ?? new Date()
      // ),
      'Company name': shopInfo?.data?.name,
      'Company Shopify Domain': shopInfo?.data?.shopify_domain,
      'Company Shopify Plan': shopInfo?.data?.shopify_plan,
    };
    if (isShopifyEmbedded()) {
      if (shopInfo?.data?.shopify_domain) {
  window.clarity(
          'identify',
          shopInfo?.data?.shopify_domain,
          undefined,
          undefined,
          shopInfo?.data?.name
        );
        const created_at = new Date(shopInfo?.data?.created_at).getTime();
        growthbook.setAttributes({
          name: shopInfo?.data?.name,
          shopify_domain: shopInfo?.data?.shopify_domain,
          created_at,
        });
      }

      window.Intercom('boot', {
        //app_id: 'z6z6hpk3',
        app_id: 'uo1jz672',
        source_app: 'discount-prime-test',
        name: shopData.name,
        ...shopData,
      });

      window._loq = window._loq || [];
      window._loq.push(['custom', { app: 'discount-prime-test', ...shopData }]);
    } else {
      window.Intercom('boot', {
        app_id: 'uo1jz672',
        // app_id: 'u5qsddiv', production
        source_app: 'discount-prime-test',
        name: shopData.name,
        ...shopData,
      });

      window._loq = window._loq || [];
      window._loq.push(['custom', { app: 'discount-prime-test', ...shopData }]);
    }
  }, [shopInfo]);

  if (loadingShopInfo || loadingPermissions)
    return (
      <>
        <WholePageLoading />
        <img
          src={tbImg}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            zIndex: -1000,
            transform: 'translate(-50%, -50%)',
          }}
        />
      </>
    );
  // if (loadingShopInfo) return <></>;

  const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    host: shopInfo?.host,
    forceRedirect: false,
  };

  return (
    <>
      {/* <img
        src="assets/images/tb.jpg"
        style={{ position: 'fixed', top: 0, left: 0, zIndex: -1000 }}
      /> */}
      <GrowthBookProvider growthbook={growthbook}>
        <PolarisProvider i18n={enTranslations} theme={'light'} linkComponent={RouterLink}>
          {isLoggedInAsUser ? (
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          ) : (
            <BrowserRouter>
              <AppBridgeProvider config={config}>
                <AppContent />
                <NavigationMenu
                  //  matcher={(link, location) => link.destination === location.pathname}
                  navigationLinks={[
                    // {
                    //   label: t('home'),
                    //   destination: PATHS.HOME,
                    // },
                    ...(canAddCampaign?[
                    { label: t('create_campaign'), destination: PATHS.CREATE_CAMPAIGN }] // Added link to Create Campaign
                    :[]),
                    {
                      label: t('campaigns'),
                      destination: PATHS.CAMPAIGNS,
                    },
                    {
                      label: 'Widgets',
                      destination: PATHS.WIDGETS,
                    },
                    {
                      label: t('settings'),
                      destination: PATHS.SETTING,
                    },
                    // ...(permissions.can_set_product_countdown_settings
                    //   ? [
                    //       {
                    //         label: 'Countdown Settings',
                    //         destination: PATHS.COUNTDOWN_SETTINGS,
                    //       },
                    //     ]
                    //   : []),
                    {
                      label: t('plans'),
                      destination: PATHS.PRICING,
                    },
                    // {
                    //   label: t('feature_request'),
                    //   destination: PATHS.FEATURE_REQUEST,
                    // },
                    {
                      label: t('help'),
                      destination: PATHS.HELP,
                    },
                  ]}
                />

                <Router />
              </AppBridgeProvider>
            </BrowserRouter>
          )}
        </PolarisProvider>
      </GrowthBookProvider>
    </>
  );
};

export default App;

function AppContent() {
  let app;
  try {
  } catch (error) {}
  return (
    <>
      {((app) => {
        // Do something with App Bridge `app` instance...
        if (app) {
          app.getState().then((state) => console.log(state));
        }

        return <span></span>;
      })(app)}
    </>
  );
}
